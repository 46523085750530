import { getItem, setItem } from "@buzzeasy/shared-frontend-utilities";

function tryGetLegacyValue<T>(key: string, type: string): T | null {
  const legacyValue = localStorage.getItem(key);

  if (legacyValue === null)
    return null;

  switch (type) {
    case "string":
      return legacyValue as T;
    case "number": {
      const parsedValue = Number.parseInt(legacyValue);

      if (Number.isNaN(parsedValue))
        return null;
      else
        return parsedValue as T;
    }
    case "boolean":
      return (legacyValue === "true") as T;
    default:
      return null;
  }
}

export function getOrCreateItem<T>(key: string, defaultValue: T): T {
  try {
    const value = getItem<T>(key);

    if (value === null) {
      setItem(key, defaultValue);
      return defaultValue;
    }

    return value;
  }
  catch {
    const value = tryGetLegacyValue<T>(key, typeof defaultValue) ?? defaultValue;
    setItem(key, value);
    return value;
  }
}
