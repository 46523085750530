import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { ConnectionStatus } from "../redux/widget/widgetSlice";

interface IProps {
  notificationMessage?: string;
  connectionStatus: ConnectionStatus;
}

const RootContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const NotificationContainer = styled.div`
  display: flex;
  padding: .5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  background-color: #D0342C;
  color: white;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingAnimation = styled.svg`
  width: 0.8rem;
  height: 0.8rem;
  animation: ${spin} 1s linear infinite;
`;

const RefreshIcon = styled.svg`
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
`;

const TextContainer = styled.div`
  font-weight: 600;
`;

const NotificationBar: FC<IProps> = ({ connectionStatus, notificationMessage }) => {
  return (
    <RootContainer>
      <NotificationContainer>
        {notificationMessage && <TextContainer>{notificationMessage}</TextContainer>}
        {
          !notificationMessage && connectionStatus === "disconnected" &&
          <>
            <RefreshIcon viewBox="0 0 20 20" fill="currentColor" onClick={() => window.location.reload()}>
              <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
            </RefreshIcon>
            <TextContainer>Reload page</TextContainer>
          </>
        }
        {
          !notificationMessage && connectionStatus === "reconnecting" &&
          <>
            <LoadingAnimation xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle style={{ opacity: ".25" }} cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path style={{ opacity: ".75" }} fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </LoadingAnimation>
            <TextContainer>Reconnecting...</TextContainer>
          </>
        }
      </NotificationContainer>
    </RootContainer>
  );
};

export default NotificationBar;
