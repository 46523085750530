import { CustomerTrackingDetails } from "../models/signalR/Tracking";
import { getTrackingDetails } from "../signalr/useCustomerTrackingHub";

export interface IConversationData {
  workflowData: Record<string, string>;
  customerData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

declare global {
  interface Window {
    getDataForBuzzeasy(): Promise<IConversationData> | undefined;
  }
}

async function getBaseConversationData(): Promise<IConversationData | undefined> {
  if (!window.getDataForBuzzeasy)
    return undefined;

  if (typeof window.getDataForBuzzeasy !== "function") {
    // eslint-disable-next-line no-console
    console.error("Cannot pass on Buzzeasy conversation data because window.%cgetDataForBuzzeasy%c is not a function.", "color: #facc15", "color: unset");
    return undefined;
  }

  const result = await window.getDataForBuzzeasy();

  if (JSON.stringify(result).length > 150000) {
    // eslint-disable-next-line no-console
    console.error("Cannot pass on Buzzeasy conversation data because its size is bigger than 150Kb.");
    return undefined;
  }

  return result;
}

export function normalizeTrackingDetails(trackingDetails: CustomerTrackingDetails): Record<string, string> {
  return Object.fromEntries(
    Object.entries(trackingDetails)
      .map(([key, value]) => [`internal_webchat_visitor_${key}`, value instanceof Date ? value.toISOString() : value]),
  );
}

function assembleConversationDataWithTrackingDetails(conversationData: IConversationData | undefined, trackingDetails: CustomerTrackingDetails): Partial<IConversationData> {
  return {
    workflowData: {
      ...conversationData?.workflowData,
      ...normalizeTrackingDetails(trackingDetails),
    },
    customerData: conversationData?.customerData,
  };
}

export async function getConversationData(): Promise<Partial<IConversationData>> {
  const conversationData = await getBaseConversationData();
  const trackingDetails = getTrackingDetails();

  return assembleConversationDataWithTrackingDetails(conversationData, trackingDetails);
}
