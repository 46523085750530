import { readableColor, rgba } from "polished";
import { FC } from "react";
import styled from "styled-components";
import { Color } from "../models/signalR/WidgetConfiguration";

function getDividerColor(backgroundColor: Color): string {
  return rgba(readableColor(backgroundColor, "#404040", "#c0c0c0"), 0.75);
}

const DividerContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  user-select: none;
`;

const DividerLine = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 0.1rem solid ${p => getDividerColor(p.theme.background)}; // hex color extended with alpha
  line-height: 0rem;
`;

const DividerText = styled.span`
  font-size: 0.76rem;
  font-weight: 500;
  color: ${p => getDividerColor(p.theme.background)};
  background: ${p => p.theme.background}; 
  padding: 0 0.7rem;
`;

interface IProps {
  text?: string;
}

const Divider: FC<IProps> = ({ text }) => (
  <DividerContainer role="separator">
    <DividerLine>
      <DividerText>{text}</DividerText>
    </DividerLine>
  </DividerContainer>
);

export default Divider;
