type Key =
  | "ADD_ATTACHMENT"
  | "ADD_EMOJI"
  | "CLOSE_CONVERSATION"
  | "CLOSE_MENU"
  | "CLOSE_WIDGET"
  | "CONVERSATION_ENDED"
  | "DOWNLOAD_TRANSCRIPT"
  | "EMPTY_FILE_UPLOAD_DISABLED"
  | "FAILED_TO_UPLOAD_FILE"
  | "INPUT_MESSAGE_LABEL"
  | "INPUT_MESSAGE_PLACEHOLDER"
  | "OPEN_MENU"
  | "OPEN_WIDGET"
  | "POLICY_LINK"
  | "SEND_MESSAGE"
  | "TOGGLE_NOTIFICATION_SOUND"
  | "FILE_SIZE_MESSAGE"
  | "FILE_TYPE_NOT_SUPPORTED_MESSAGE"
  | "EMPTY_FILE_MESSAGE"
  | "FILE_NAME_IS_MISSING_MESSAGE"
  | "SINGLE_FILE_SUPPORTED_MESSAGE"
  | "UNKNOWN_FILE_UPLOAD_ERROR"
  | "PREPARING_TRANSCRIPT"
  | "FAILED_TO_DOWNLOAD_TRANSCRIPT"
  ;

const en: Record<Key, string> = {
  ADD_ATTACHMENT: "Add attachment",
  ADD_EMOJI: "Add emoji",
  CLOSE_CONVERSATION: "Close conversation",
  CLOSE_MENU: "Close menu",
  CLOSE_WIDGET: "Close chat widget",
  CONVERSATION_ENDED: "Conversation ended",
  DOWNLOAD_TRANSCRIPT: "Transcript",
  EMPTY_FILE_UPLOAD_DISABLED: "Empty file upload disabled",
  INPUT_MESSAGE_LABEL: "Message",
  INPUT_MESSAGE_PLACEHOLDER: "Type your message",
  FAILED_TO_UPLOAD_FILE: "Failed to upload file",
  OPEN_MENU: "Open menu",
  OPEN_WIDGET: "Open chat widget",
  POLICY_LINK: "Privacy policy",
  SEND_MESSAGE: "Send message",
  TOGGLE_NOTIFICATION_SOUND: "Notification sound",
  FILE_SIZE_MESSAGE: "File must be smaller than 10 MB.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "File type is not supported.",
  EMPTY_FILE_MESSAGE: "You can't upload an empty file.",
  FILE_NAME_IS_MISSING_MESSAGE: "File name is missing.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "Currently only single file upload is supported.",
  UNKNOWN_FILE_UPLOAD_ERROR: "Unknown file upload error.",
  PREPARING_TRANSCRIPT: "Please wait while we are preparing your transcript.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "Failed to download transcript.",
};

const hu: Record<Key, string> = {
  ADD_ATTACHMENT: "Csatolmány hozzáadása",
  ADD_EMOJI: "Hangulatjel hozzáadása",
  CLOSE_CONVERSATION: "Beszélgetés lezárása",
  CLOSE_MENU: "Menü megnyitása",
  CLOSE_WIDGET: "Beszélgető felület becsukása",
  CONVERSATION_ENDED: "Beszélgetés vége",
  DOWNLOAD_TRANSCRIPT: "Másolat",
  EMPTY_FILE_UPLOAD_DISABLED: "Üres fájlt nem lehet feltölteni",
  FAILED_TO_UPLOAD_FILE: "Hiba történt a fájlfeltöltés közben",
  INPUT_MESSAGE_LABEL: "Üzenet",
  INPUT_MESSAGE_PLACEHOLDER: "Üzenet beírása",
  OPEN_MENU: "Menü bezárása",
  OPEN_WIDGET: "Beszélgető felület kinyitása",
  POLICY_LINK: "Adatvédelmi irányelvek",
  SEND_MESSAGE: "Üzenet küldése",
  TOGGLE_NOTIFICATION_SOUND: "Értesítés hang",
  FILE_SIZE_MESSAGE: "A fájl mérete nem haladhatja meg a 10 MB-ot.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "A fájltípus nem támogatott.",
  EMPTY_FILE_MESSAGE: "Üres fájlt nem tölthet fel.",
  FILE_NAME_IS_MISSING_MESSAGE: "Hiányzik a fájlnév.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "Jelenleg csak egyetlen fájl feltöltése támogatott.",
  UNKNOWN_FILE_UPLOAD_ERROR: "Ismeretlen fájlfeltöltési hiba.",
  PREPARING_TRANSCRIPT: "Kérjük, várjon, amíg elkészítjük az átíratot.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "Az átirat letöltése sikertelen.",
};

const bg: Record<Key, string> = {
  CLOSE_CONVERSATION: "Прекратете чата",
  CONVERSATION_ENDED: "Разговорът приключи",
  DOWNLOAD_TRANSCRIPT: "Препис",
  POLICY_LINK: "политика за поверителност",
  INPUT_MESSAGE_PLACEHOLDER: "напишете вашето съобщение",
  FAILED_TO_UPLOAD_FILE: "Неуспешно качване на файл",
  EMPTY_FILE_UPLOAD_DISABLED: "Изключено е качването на празни файлове",
  ADD_ATTACHMENT: "Добавяне на прикачен файл",
  ADD_EMOJI: "Добавяне на емотикони",
  CLOSE_MENU: "Затвори менюто",
  CLOSE_WIDGET: "Затворете уиджета за чат",
  INPUT_MESSAGE_LABEL: "Съобщение",
  OPEN_MENU: "Отваряне на менюто",
  OPEN_WIDGET: "Отворена джаджа за чат",
  SEND_MESSAGE: "изпращане на съобщение",
  TOGGLE_NOTIFICATION_SOUND: "Звук за известяване",
  FILE_SIZE_MESSAGE: "Размерът на файла не трябва да надвишава 10 MB.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "Типът на файла не се поддържа.",
  EMPTY_FILE_MESSAGE: "Не можете да качвате празен файл.",
  FILE_NAME_IS_MISSING_MESSAGE: "Липсва името на файла.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "В момента се поддържа само качване на единичен файл.",
  UNKNOWN_FILE_UPLOAD_ERROR: "Неизвестна грешка при качването на файла.",
  PREPARING_TRANSCRIPT: "Моля изчакайте, докато подготвим вашия транскрипт.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "Неуспешно изтегляне на транскрипта.",
};

const sr: Record<Key, string> = {
  CLOSE_CONVERSATION: "Završi ćaskanje",
  CONVERSATION_ENDED: "Razgovor je završen",
  DOWNLOAD_TRANSCRIPT: "Prepis",
  POLICY_LINK: "Pravila o privatnosti",
  INPUT_MESSAGE_PLACEHOLDER: "Unesite svoju poruku",
  FAILED_TO_UPLOAD_FILE: "Otpremanje datoteke nije uspelo",
  EMPTY_FILE_UPLOAD_DISABLED: "Otpremanje prazne datoteke je onemogućeno",
  ADD_ATTACHMENT: "Dodaj prilog",
  ADD_EMOJI: "Dodajte emoji",
  CLOSE_MENU: "Zatvori meni",
  CLOSE_WIDGET: "Zatvorite vidžet za ćaskanje",
  INPUT_MESSAGE_LABEL: "Poruka",
  OPEN_MENU: "Otvorite meni",
  OPEN_WIDGET: "Otvorite vidžet za ćaskanje",
  SEND_MESSAGE: "Pošalji poruku",
  TOGGLE_NOTIFICATION_SOUND: "Zvok obvestila",
  FILE_SIZE_MESSAGE: "Datoteka mora biti manja od 10 MB.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "Tip datoteke nije podržan.",
  EMPTY_FILE_MESSAGE: "Ne možete da otpremite praznu datoteku.",
  FILE_NAME_IS_MISSING_MESSAGE: "Nedostaje ime datoteke.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "Trenutno je podržano samo jednostruko otpremanje datoteke.",
  UNKNOWN_FILE_UPLOAD_ERROR: "Nepoznata greška pri otpremanju datoteke.",
  PREPARING_TRANSCRIPT: "Molimo sačekajte dok pripremamo vaš transkript.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "Ne успешно преузимање транскрипта.",
};

const he: Record<Key, string> = {
  ADD_ATTACHMENT: "הוסף קובץ מצורף",
  ADD_EMOJI: "הוסף אימוג'י",
  CLOSE_CONVERSATION: "שיחה קרובה",
  CLOSE_MENU: "סגור תפריט",
  CLOSE_WIDGET: "סגור את ווידג'ט הצ'אט",
  CONVERSATION_ENDED: "השיחה הסתיימה",
  DOWNLOAD_TRANSCRIPT: "תמלול",
  EMPTY_FILE_UPLOAD_DISABLED: "העלאת קובץ ריק מושבתת",
  INPUT_MESSAGE_LABEL: "הוֹדָעָה",
  INPUT_MESSAGE_PLACEHOLDER: "הקלד את ההודעה שלך",
  FAILED_TO_UPLOAD_FILE: "העלאת הקובץ נכשלה",
  OPEN_MENU: "פתח את התפריט",
  OPEN_WIDGET: "פתח את ווידג'ט הצ'אט",
  POLICY_LINK: "מדיניות הפרטיות",
  SEND_MESSAGE: "לשלוח הודעה",
  TOGGLE_NOTIFICATION_SOUND: "צליל התראה",
  FILE_SIZE_MESSAGE: "הקובץ חייב להיות קטן מ־10 MB.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "סוג הקובץ אינו נתמך.",
  EMPTY_FILE_MESSAGE: "אין אפשרות להעלות קובץ ריק.",
  FILE_NAME_IS_MISSING_MESSAGE: "שם הקובץ חסר.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "כרגע רק העלאת קובץ יחיד נתמכת.",
  UNKNOWN_FILE_UPLOAD_ERROR: "שגיאת העלאת קובץ לא ידועה.",
  PREPARING_TRANSCRIPT: "אנא המתן בזמן שאנו מכינים את התמלול שלך.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "הורדת התמלול נכשלה.",
};

const sl: Record<Key, string> = {
  ADD_ATTACHMENT: "Dodajte priponko",
  ADD_EMOJI: "Dodajanje emotikonov",
  CLOSE_CONVERSATION: "Tesen pogovor",
  CLOSE_MENU: "Zapri meni",
  CLOSE_WIDGET: "Zapri pripomoček za klepet",
  CONVERSATION_ENDED: "Pogovor se je končal",
  DOWNLOAD_TRANSCRIPT: "Prepis",
  EMPTY_FILE_UPLOAD_DISABLED: "Prenos praznih datotek ni dovoljen",
  INPUT_MESSAGE_LABEL: "Sporočilo",
  INPUT_MESSAGE_PLACEHOLDER: "Napišite svoje sporočilo",
  FAILED_TO_UPLOAD_FILE: "Ni uspelo naložiti datoteke",
  OPEN_MENU: "Odprti meni",
  OPEN_WIDGET: "Odprt pripomoček za klepet",
  POLICY_LINK: "Politika zasebnosti",
  SEND_MESSAGE: "Pošlji sporočilo",
  TOGGLE_NOTIFICATION_SOUND: "Zvočno obvestilo",
  FILE_SIZE_MESSAGE: "Datoteka mora biti manjša od 10 MB.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "Vrsta datoteke ni podprta.",
  EMPTY_FILE_MESSAGE: "Ne morete naložiti prazne datoteke.",
  FILE_NAME_IS_MISSING_MESSAGE: "Manjka ime datoteke.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "Trenutno je podprto samo enotno nalaganje datoteke.",
  UNKNOWN_FILE_UPLOAD_ERROR: "Neznana napaka pri nalaganju datoteke.",
  PREPARING_TRANSCRIPT: "Prosim, počakajte, medtem ko pripravljamo vaš transkript.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "Prenos transkripta ni uspel.",
};

const de: Record<Key, string> = {
  ADD_ATTACHMENT: "Anhang hinzufügen",
  ADD_EMOJI: "Emoji hinzufügen",
  CLOSE_CONVERSATION: "Konversation schließen",
  CLOSE_MENU: "Menü schließen",
  CLOSE_WIDGET: "Chat-Widget schließen",
  CONVERSATION_ENDED: "Konversation beendet",
  DOWNLOAD_TRANSCRIPT: "Transkript herunterladen",
  EMPTY_FILE_UPLOAD_DISABLED: "Leere Datei-Upload deaktiviert",
  INPUT_MESSAGE_LABEL: "Nachricht",
  INPUT_MESSAGE_PLACEHOLDER: "Geben Sie Ihre Nachricht ein",
  FAILED_TO_UPLOAD_FILE: "Fehler beim Hochladen der Datei",
  OPEN_MENU: "Menü öffnen",
  OPEN_WIDGET: "Chat-Widget öffnen",
  POLICY_LINK: "Datenschutzrichtlinie",
  SEND_MESSAGE: "Nachricht senden",
  TOGGLE_NOTIFICATION_SOUND: "Benachrichtigungston umschalten",
  FILE_SIZE_MESSAGE: "Datei muss kleiner als 10 MB sein.",
  FILE_TYPE_NOT_SUPPORTED_MESSAGE: "Dateityp wird nicht unterstützt.",
  EMPTY_FILE_MESSAGE: "Sie können keine leere Datei hochladen.",
  FILE_NAME_IS_MISSING_MESSAGE: "Dateiname fehlt.",
  SINGLE_FILE_SUPPORTED_MESSAGE: "Derzeit wird nur das Hochladen einer einzelnen Datei unterstützt.",
  UNKNOWN_FILE_UPLOAD_ERROR: "Unbekannter Fehler beim Hochladen der Datei.",
  PREPARING_TRANSCRIPT: "Bitte warten Sie, während wir Ihr Transkript vorbereiten.",
  FAILED_TO_DOWNLOAD_TRANSCRIPT: "Transkript konnte nicht heruntergeladen werden.",
};

const rtlLanguages = ["HE"];

class TranslationService {
  private _currentLanguage: string;

  private _direction: string;

  constructor() {
    this._currentLanguage = "EN";
    this._direction = "ltr";
  }

  setCurrentLanguage(language: string): void {
    this._currentLanguage = language;
    this._direction = rtlLanguages.includes(language) ? "rtl" : "ltr";
  }

  overrideLanguageDirection(dir: string): void {
    this._direction = dir;
  }

  getTranslation(key: Key): string {
    switch (this._currentLanguage) {
      case "EN":
        return en[key];
      case "HU":
        return hu[key];
      case "SR":
        return sr[key];
      case "BG":
        return bg[key];
      case "HE":
        return he[key];
      case "SL":
        return sl[key];
      case "DE":
        return de[key];
      default:
        return en[key];
    }
  }

  getDirection(): string {
    return this._direction;
  }
}
const service = new TranslationService();
export default service;
