import { readableColor, rgba } from "polished";
import { FC } from "react";
import styled, { keyframes } from "styled-components";
import { Color } from "../models/signalR/WidgetConfiguration";

function getColor(backgroundColor: Color): string {
  return rgba(readableColor(backgroundColor, "#404040", "#c0c0c0"), 0.75);
}

const RootContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
  gap: 0.5rem;
  color: ${p => getColor(p.theme.background)};
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingAnimation = styled.svg`
  width: 1rem;
  height: 1rem;
  animation: ${spin} 1s linear infinite;
`;

const TextContainer = styled.div`
  user-select: none;
`;

// TODO: add translation

const MessageHistoryLoading: FC = () => {
  return (
    <RootContainer>
      <LoadingAnimation xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle style={{ opacity: ".25" }} cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path style={{ opacity: ".75" }} fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </LoadingAnimation>
      <TextContainer>loading history...</TextContainer>
    </RootContainer>
  );
};

export default MessageHistoryLoading;
