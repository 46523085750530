import { FC } from "react";
import styled from "styled-components";
import { readableColor } from "polished";
import ChatMessageItem from "./ChatMessageItem";

const AttachmentDownloadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  gap: 0.5rem;
`;

const AttachmentName = styled.p`
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: unset;
`;

const Link = styled.a<AttachmentProps>`
  color: ${props => readableColor(props.isMine ? props.theme.outgoingMessageBackground : props.theme.incomingMessageBackground)};
`;

interface IProps {
  timestamp: string;
  contentUrl: string;
  attachmentName: string;
  isMine: boolean;
}

interface AttachmentProps {
  isMine: boolean;
}
const GenericAttachment: FC<IProps> = ({ timestamp, contentUrl, isMine, attachmentName }) => {
  return (
    <ChatMessageItem
      timestamp={timestamp}
      message={
        <Link href={contentUrl} isMine={isMine} target="_blank" rel="noopener noreferrer">
          <AttachmentDownloadContainer>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "1.5rem", height: "1.5rem" }} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            <AttachmentName>
              Download
              {attachmentName}
            </AttachmentName>
          </AttachmentDownloadContainer>
        </Link>
      }
      isMine={isMine}
    />
  );
};

export default GenericAttachment;
