import { FC, ReactNode } from "react";
import styled from "styled-components";

interface IProps {
  title: string | undefined;
  subtitle: string;
  avatarUrl: string | undefined;
  children: ReactNode;
}

const HeaderContainer = styled.div`
  background-color: ${props => props.theme.headerBackground};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const AvatarImageContainer = styled.div`
  flex: 1 1;
  min-width: 4rem;
  display: flex;
`;

const AvatarImage = styled.img`
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 9999px;
  border: 2px solid ${props => props.theme.headerFont};
  object-fit: cover;
  margin: auto;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`;

const TextContainer = styled.div`
  flex: 10 1;
  color: ${props => props.theme.headerFont};
  padding-left: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 270px) {
     width: 10%;
  }
`;

const Title = styled.p`
  font-size: 1.15rem;
  line-height: 1.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-block-start: unset;
  margin-block-end: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const SubTitle = styled.p`
  font-size: 0.8rem;
  font-weight: 300;
  letter-spacing: 0.025em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-block-start: unset;
  margin-block-end: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Header: FC<IProps> = ({ avatarUrl, title, subtitle, children }) => {
  return (
    <HeaderContainer>
      <AvatarImageContainer>
        <AvatarImage src={avatarUrl} alt="Contact image" />
      </AvatarImageContainer>
      <TextContainer>
        <Title title={title}>{title}</Title>
        <SubTitle aria-label="Contact name">{subtitle}</SubTitle>
      </TextContainer>
      {children}
    </HeaderContainer>
  );
};

export default Header;
