import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { widgetAttributesParser } from "../../utils/widgetAttributesParser";
import { IConversationStatus, IWidgetSettings } from "../../models/signalR/WidgetConfiguration";
import { IHeaderUpdate } from "../../models/signalR/HeaderUpdate";

export type ConversationStatusResponse = {
  isActive: boolean;
  headerInfo: IHeaderUpdate | null;
};

const getSettingsQuery = (conversationId: string) => {
  const { url, channelId, tenantId } = widgetAttributesParser();
  return `${url.origin}/webchat/tenants/${tenantId}/channels/${channelId}/clientsettings?conversationId=${conversationId}`;
};

const getConversationStatusQuery = (conversationId: string) => {
  const { url, channelId, tenantId } = widgetAttributesParser();
  return `${url.origin}/webchat/tenants/${tenantId}/channels/${channelId}/clientsettings/isActiveConversation?conversationId=${conversationId}`;
};

export const widgetApi = createApi({
  reducerPath: "widgetApi",
  baseQuery: fetchBaseQuery(),
  endpoints: builder => ({
    getSettings: builder.query<IWidgetSettings, string>({
      query: conversationId => getSettingsQuery(conversationId),
    }),
    getConversationStatus: builder.query<ConversationStatusResponse, string>({
      query: conversationId => getConversationStatusQuery(conversationId),
      transformResponse: (status: IConversationStatus) => {
        const headerInfo: IHeaderUpdate = {
          avatarUrl: status.avatarUrl,
          displayName: status.displayName,
          headerText: status.title,
        };

        return { isActive: !status.isNewConversation, headerInfo };
      },
    }),
  }),
});

export const { useGetSettingsQuery, useGetConversationStatusQuery } = widgetApi;
