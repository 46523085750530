import { FC, ReactNode } from "react";
import styled from "styled-components";

interface IProps {
  isFullscreenView: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  header: ReactNode;
  messages: ReactNode;
  input: ReactNode;
  notificationBar?: ReactNode;
}

const Root = styled.div<{ isFullscreenView: boolean }>`
  border-radius:  ${props => !props.isFullscreenView && "0.8rem"};
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 30 1;
  overflow-y: auto;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

const InputBoxContainer = styled.div`
  flex: 1 0;
  min-height: 2.7rem;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  flex: 1 0;
  min-height: 4rem;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

const NotificationBarContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 1.4rem;
`;

const ChatBoxLayout: FC<IProps> = ({ onClick, isFullscreenView, header, messages, input, notificationBar }) => {
  return (
    <Root onClick={onClick} isFullscreenView={isFullscreenView}>
      <HeaderContainer>
        {header}
        {
          notificationBar &&
          <NotificationBarContainer>
            {notificationBar}
          </NotificationBarContainer>
        }
      </HeaderContainer>
      <ContentContainer>
        {messages}
      </ContentContainer>
      <InputBoxContainer>
        {input}
      </InputBoxContainer>
    </Root>
  );
};

export default ChatBoxLayout;
