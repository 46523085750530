import { FC } from "react";
import styled from "styled-components";

interface IProps {
  actions: string[];
  disabled: boolean;
  onSelect(action: string): void;
}

const Container = styled.div`
  width: 18rem;
  padding: .1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5rem;
`;

const ItemContainer = styled.button<{ disabled: boolean }>`
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  max-width: 18rem;
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 2px 5px #00000030;
  font-size: 0.8rem;
  background-color: ${props => props.theme.suggestedActionBackground};
  cursor: ${props => props.disabled ? "default" : "pointer"};
  user-select: none;
  
  transition: 0.15s ease-in-out;
  transition-property: border-color, box-shadow;

  color: ${props => props.disabled ? `${props.theme.suggestedActionFont}a0` : props.theme.suggestedActionFont};

  :hover {
    border-color: ${props => props.disabled ? "transparent" : props.theme.headerBackground};
    box-shadow: ${props => props.disabled ? "0 2px 5px #00000030" : "0 0 5px " + props.theme.headerBackground};
  }
`;

const TextContainer = styled.div`
  word-break: break-all;
`;

const SuggestedActions: FC<IProps> = ({ actions, disabled, onSelect }) => {
  if (!actions.length)
    return null;

  return (
    <Container>
      {
        actions.map((value, idx) =>
          <ItemContainer
            disabled={disabled}
            key={idx}
            onClick={() => !disabled && onSelect(value)}
          >
            <TextContainer>
              {value}
            </TextContainer>
          </ItemContainer>,
        )
      }
    </Container>
  );
};

export default SuggestedActions;
