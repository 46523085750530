import { darken, lighten, readableColor } from "polished";
import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Color } from "../models/signalR/WidgetConfiguration";

function linkColor(fontColor: Color): string {
  return readableColor(fontColor, darken(0.10, fontColor), lighten(0.10, fontColor), false);
}

interface IProps {
  timestamp: string;
  message: ReactNode;
  isMine: boolean;
}

interface ContainerProps {
  isMine: boolean;
}

const RootContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${props => props.isMine ? "end" : "start"};
`;

const MessageContainer = styled.div<ContainerProps>`
  padding: 0.5rem;
  display: inline-block;
  background-color: ${props => props.isMine ? props.theme.outgoingMessageBackground : props.theme.incomingMessageBackground};
  color: ${props => props.isMine ? props.theme.outgoingMessageFont : props.theme.incomingMessageFont};
  border-radius: .3rem;
  max-width: 75%;
  min-width: 30%;

  a {
    color: ${props => linkColor(props.isMine ? props.theme.outgoingMessageFont : props.theme.incomingMessageFont)};
  }
`;

const TimestampContainer = styled.div<ContainerProps>`
  font-size: 0.75rem;
  color: ${props => props.isMine ? props.theme.outgoingMessageFont : props.theme.incomingMessageFont};
`;

const ChatMessageItem: FC<IProps> = ({ timestamp, message, isMine }) => (
  <RootContainer isMine={isMine} data-direction={isMine ? "outbound" : "inbound"}>
    <MessageContainer isMine={isMine}>
      <TimestampContainer isMine={isMine}>{timestamp}</TimestampContainer>
      {message}
    </MessageContainer>
  </RootContainer>
);

export default ChatMessageItem;
