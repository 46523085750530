import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { nanoid } from "nanoid";
import { publicIpv4 } from "public-ip";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StartConversationCommand } from "../models/signalR/StartConversationCommand";
import { CustomerTrackingDetails } from "../models/signalR/Tracking";
import { ICustomerTracking } from "../models/signalR/WidgetConfiguration";
import { useConfigContext } from "../providers/ConfigProvider";
import { useAppSelector } from "../redux/hooks";
import { getOrCreateItem } from "../utils/localStorageHelpers";

const clientIdStorageKey = "buzzeasy_client_id";
const siteOpenedAtStorageKey = "buzzeasy_tab_opened_at";

const clientId = getOrCreateItem<string>(clientIdStorageKey, nanoid());
const urlVisitedAt = new Date();
const siteOpenedAt = (function getOrCreateSiteOpenedAt() {
  let siteOpenedAtFromStorage = sessionStorage.getItem(siteOpenedAtStorageKey);
  if (siteOpenedAtFromStorage === null) {
    siteOpenedAtFromStorage = urlVisitedAt.toISOString();
    sessionStorage.setItem(siteOpenedAtStorageKey, siteOpenedAtFromStorage);
  }
  return new Date(siteOpenedAtFromStorage);
})();

export function getTrackingDetails(): CustomerTrackingDetails {
  return {
    siteOpenedAt,
    urlVisitedAt,
    pageTitle: document.title,
    url: window.location.href,
  };
}

function ipToNumber(ipAddress: string) {
  return Number(
    ipAddress.split(".")
      .map(num => num.padStart(3, "0"))
      .join(""),
  );
}

async function getShouldTrackThisConnection(trackingConfig: ICustomerTracking) {
  if (!trackingConfig.enabled)
    return false;

  const currentPath = window.location.pathname;

  if (!trackingConfig.pages.some(trackedPath => currentPath.startsWith(trackedPath)))
    return false;

  const mappedRanges = trackingConfig.filteredIPRanges.map(({ rangeStart, rangeEnd }) => ({ rangeStart: ipToNumber(rangeStart), rangeEnd: ipToNumber(rangeEnd) }));
  const ip = ipToNumber(await publicIpv4());

  if (mappedRanges.some(({ rangeStart, rangeEnd }) => rangeStart <= ip && ip <= rangeEnd))
    return false;

  return true;
}

interface IHandlers {
  onStartConversation(message: StartConversationCommand): void;
}

const useCustomerTrackingHub = (handlers: IHandlers) => {
  const { trackingHubUrl, settings: { customerTracking: trackingSettings } } = useConfigContext();
  const conversationStatus = useAppSelector(state => state.chat.status);
  const eventHandlersRef = useRef<IHandlers>(handlers);

  const dispatchEvent = useCallback(
    <TKey extends keyof IHandlers>(name: TKey, ...args: Parameters<IHandlers[TKey]>) => {
      // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter.ts(2556)
      eventHandlersRef.current[name](...args);
    },
    [],
  );

  const connection = useMemo(
    () => {
      const conn = new HubConnectionBuilder()
        .withUrl(`${trackingHubUrl}&clientId=${clientId}`)
        .withAutomaticReconnect()
        .build();

      void [
        { name: "StartConversation", handler: (cmd: StartConversationCommand) => dispatchEvent("onStartConversation", cmd) },
      ].forEach(m => conn.on(m.name, m.handler));

      return conn;
    },
    [trackingHubUrl, dispatchEvent],
  );

  const sendTrackingDetails = useCallback(
    () => {
      if (connection.state === HubConnectionState.Connected)
        void connection.send("AddOrUpdateSiteInteraction", getTrackingDetails());
    },
    [connection],
  );

  const [shouldTrackThisConnection, setShouldTrackThisConnection] = useState(false);
  const lastUrlPath = useRef(window.location.pathname);

  // start / stop connection based on the tracking settings
  useEffect(
    () => {
      async function handleShouldTrackChanges() {
        if (shouldTrackThisConnection && conversationStatus === "inactive") {
          if (connection.state === HubConnectionState.Disconnected)
            await connection?.start();

          sendTrackingDetails();
        }
        else {
          if (connection.state === HubConnectionState.Connected)
            await connection.stop();
        }
      }

      void handleShouldTrackChanges();
    },
    [connection, conversationStatus, sendTrackingDetails, shouldTrackThisConnection],
  );

  // initialize shouldTrackThisConnection and listen for URL changes
  useEffect(
    () => {
      // update state with the initial value
      void getShouldTrackThisConnection(trackingSettings)
        .then(setShouldTrackThisConnection);

      // timer to check for URL changes
      const interval = setInterval(
        async () => {
          if (window.location.href !== lastUrlPath.current) {
            lastUrlPath.current = window.location.href;

            const shouldTrack = await getShouldTrackThisConnection(trackingSettings);
            setShouldTrackThisConnection(shouldTrack);

            if (shouldTrack)
              sendTrackingDetails();
          }
        },
        1000,
      );

      return () => { clearInterval(interval); };
    },
    [sendTrackingDetails, trackingSettings],
  );
};

export default useCustomerTrackingHub;
